import React, { useEffect } from "react";
import imageUrl from "./../helpers/ImageUrl";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import apis from "../services";
import { CreateProjectValidate } from "../validation/CreateProjectValidation";
import { errorValidate } from "../validation/errorHandleJoi";
import toast from "react-hot-toast";
import { DashboardHeader } from "components";
import { useDispatch, useSelector } from "react-redux";
import useAuthenticate from "components/hooks/useAuthenticate";
import useAuth from "components/hooks/useAuth";
import { logout } from "redux/slices/userSlice";


const CreateProject = () => {
  const { state} = useLocation();
console.log(state?.id,"id")

  const authenticate = useAuthenticate();
  const user = useAuth();

  let [record, setRecord] = useState({
    userId: user?._id,
    name: "",
    description: "",
  });

  const onChange = (e) => {
    const { id, value } = e.target;
    setRecord((prevData) => ({ ...prevData, [id]: value }));
  };

  let [error, setError] = useState();
  const navigate = useNavigate();

  const signIn = (e) => {
    e.preventDefault();
    if (user === null) {
      authenticate();
    } else {
      let { name, ...rest } = record;
      let response = errorValidate(CreateProjectValidate({ name }));
      if (response === true) {
        navigate(`/project-design`, {
          state: { name: record?.name, id: user?._id ,description : record?.description, ...state },
        });
      } else {
        setError(response);
      }
    }
  };

  return (
    <>
      <DashboardHeader />
      <div class="account-section bg_img create-project">
        <div class="container-fluid">
          <div className="text mt-n5 mb-4">
            <p>Name your program</p>
            <p>You'll be able to modify this later.</p>
          </div>
          <div class="account-wrapper">
            <div class="account-body">
              <div class="account-form" id="contactForm">
                <div class="form-group">
                  <label for="name">Program Name</label>
                  <input
                    type="text"
                    onChange={onChange}
                    id="name"
                    placeholder="Enter Program Name"
                    name="name"
                  />
                  {error?.name ? (
                    <span className="error-message">{error?.name}</span>
                  ) : (
                    <></>
                  )}
                </div>

                <div class="form-group">
                  <label for="discription">Description</label>
                  <textarea
                    type="textarea"
                    style={{ height: "90px" }}
                    onChange={onChange}
                    id="description"
                    placeholder="Enter Program description"
                    name="description"
                  />
                </div>

                <div class="form-group text-center mt-n4 mb-n3">
                  <button onClick={signIn}>
                    {"Next"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProject;
