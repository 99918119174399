import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { Card, DashboardHeader, Header,DuplicateProjectModal, DeleteProjectModal} from "components";
import imageUrl from "helpers/ImageUrl";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import apis from "services";
import moment from "moment";

const Dashboard = () => {
  const { user } = useSelector((store) => store.user);
  console.log(user?._id,"ASSS")
 
  const { data, isLoading , refetch } = useQuery(["getUserWalletPasses"], () =>
    apis.getUserPasses(user?._id)
  );

  
  const [info, setInfo] = useState({name : '',id : '',description : '' , type : ''});

  const [status, setStatus] = useState(false);

  const [statusDuplicate, setStatusDuplicate] = useState(false);

  const toggleModalClose = () => setStatus(false);
  

  const toggleModalDuplicateClose = () => setStatusDuplicate(false);

  const toggleModal = (id,name ,type) =>{
    setInfo({name : name,id : id , type : type})
    setStatus(true);
  }

  
  const passes = data?.data?.wallet || [];

  const [active, setActive] = useState("all");
  const navigate = useNavigate();

  const setTab = (tab) => {
    setActive(tab);
  };



  const onPassEditHandler = (id) => {
    return (navigate(`/project-design/${id}`))
  }
  
  const toggleModalPassDuplicateHandler = (id,description) => {
    setInfo("")
    setInfo({id : id , description : description })
    setStatusDuplicate(true);
  }


  return (
    <>
      <Header />
      <div className="container">
        <div
          id="main_container"
          class="pageMain_container no-background wrapper page__loading"
        >
          <div className="wrapper">
            <div className="dbContent">
              <div className="row">
                <div className="col-6 css-start">
                  <button
                    onClick={() => setTab("all")}
                    className={active === "all" ? "active-btn" : ""}
                  >
                    <span>
                      {" "}
                      <i class="fa-solid fa-hand-pointer"></i>
                    </span>
                    ALL
                  </button>

                  <button
                    onClick={() => setTab("membership")}
                    className={active === "membership" ? "active-btn" : ""}
                  >
                    {" "}
                    <span>
                      {" "}
                      <i class="fa-solid fa-hand-pointer"></i>
                    </span>
                    MEMBERSHIP / LOYALITY{" "}
                  </button>
                  <button
                    onClick={() => setTab("coupons")}
                    className={active === "coupons" ? "active-btn" : ""}
                  >
                    <span>
                      {" "}
                      <i class="fa-solid fa-hand-pointer"></i>
                    </span>
                    COUPONS
                  </button>

                  <button
                    onClick={() => setTab("tickets")}
                    className={active === "tickets" ? "active-btn" : ""}
                  >
                    <span>
                      {" "}
                      <i class="fa-solid fa-hand-pointer"></i>
                    </span>
                    TICKETS
                  </button>
                </div>
                <div className="create-wrapper">
                  <div className="all-wrap">
                    <div
                      onClick={() => navigate("/project-select")}
                      className="create"
                    >
                      <div className="jss35-create">
                        <svg
                          class="jss36 css-1ytan61"
                          focusable="false"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                          data-testid="AddCircleIcon"
                        >
                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path>
                        </svg>
                        <span>Create New</span>
                      </div>
                    </div>
                  </div>{" "}
                  {passes?.map((item) => {
                    return (
                      <div className="create pass-design">
                        <p>{(item.type).toUpperCase()}</p>

                        <div className="jss35">
                          <h4 className="pass-text">{item.name}</h4>

                          <Dropdown className="boot-custom-dropdown">
                            <Dropdown.Toggle style={{backgroundColor : "white" ,color  :"black", border : "none" , marginRight : "12px"}}>
                                {/* <svg
                                  class="css-vubbuv"
                                  focusable="false"
                                  aria-hidden="true"
                                  viewBox="0 0 24 24"
                                  data-testid="MoreHorizIcon"
                                >
                                  <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
                                </svg> */}
                             </Dropdown.Toggle>
                            <Dropdown.Menu className="custome-menu">
                              <Dropdown.Item onClick={()=>onPassEditHandler(item?.wallet_Id)}>
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                              onClick={()=>toggleModalPassDuplicateHandler(item?.wallet_Id , item?.description)}
                              >
                                Duplicate
                              </Dropdown.Item>
                              <Dropdown.Item
                               onClick={()=>toggleModal(item?.wallet_Id , item?.name, item?.type)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                        <h6>
                          {moment(item?.createdAt).format(
                            "MMMM Do YYYY, h:mm a"
                          )}
                        </h6>
                      </div>
                    );
                  })}
                  {/* New */}
                  {/* <div class="css-jtl0kq">
                    <div class="jss921 css-haga45">
                      <div class="jss922 css-1d3bbye">
                        <div class="jss923 css-15j76c0"></div>
                        <div class="css-1s8mci6">
                          <div class="css-1gci44g">
                            <div class="css-1wxaqej">
                              <svg
                                class="css-vubbuv"
                                focusable="false"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                data-testid="membership-project-icon"
                                style={{
                                  color: "rgb(102, 102, 102)",
                                  width: "20px",
                                  height: "20px",
                                }}
                              >
                                <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1V19z"></path>
                              </svg>
                            </div>
                            <div class="css-1wxaqej">
                              <span class="jss927 css-ypbaar">
                                Membership / Loyalty
                              </span>
                            </div>
                          </div>
                          <div class="css-h6wf3v">
                            <button
                              class="css-1yxmbwk"
                              tabindex="0"
                              type="button"
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              data-testid="project-menu-btn"
                              style={{ padding: "0px", color: "black" }}
                            >
                              <svg
                                class="css-vubbuv"
                                focusable="false"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                data-testid="MoreHorizIcon"
                              >
                                <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
                              </svg>
                              <span class="MuiTouchRipple-root css-w0pj6f"></span>
                            </button>
                          </div>
                        </div>
                        <div class="jss924 css-1six6a8">
                          <div class="css-1wxaqej">
                            <h6 class="css-xu7k0w" data-testid="project-name">
                              My Membership Program
                            </h6>
                          </div>
                        </div>
                        <div class="css-1pfnwu6">
                          <div class="xs-3 css-1urh8pc">
                            <div class="css-ecj0ed">
                              <svg
                                class="css-vubbuv"
                                focusable="false"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                data-testid="FiberManualRecordIcon"
                                style={{
                                  height: "8px",
                                  width: "8px",
                                  color: "rgba(0, 0, 0, 0.54)",
                                }}
                              >
                                <circle cx="12" cy="12" r="8"></circle>
                              </svg>
                            </div>
                            <div class="css-rpnuf3">
                              <span
                                class="css-ypbaar"
                                data-testid="project-state"
                                style={{ color: "rgba(0, 0, 0, 0.54)" }}
                              >
                                Draft
                              </span>
                            </div>
                          </div>
                          <div class="css-z11er0">
                            <span class="jss931 css-ypbaar">
                              Created 2023/01/17 4:55
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <DuplicateProjectModal id={info?.id} description ={info?.description} status={statusDuplicate} toggleModalClose={toggleModalDuplicateClose} refetch={refetch}/>

      <DeleteProjectModal id={info?.id} name ={info?.name} type={info?.type} status={status} toggleModalClose={toggleModalClose} refetch={refetch}/>
   
    </>
  );
};

export default Dashboard;
