import React from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import imageUrl from "./../helpers/ImageUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import apis from "../services";
import { signUpValidate } from "../validation/signUpValidation";
import { errorValidate } from "../validation/errorHandleJoi";
import toast, { Toaster } from 'react-hot-toast';
import { setUser } from "redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const Signup = () => {
  const {user} = useSelector(store => store.user);
  let [data, setData] = useState({name : '',email: '', company : '' , industry : ''  })
  
  const onChange = (e) => {
    const { id, value } = e.target;
    setData(prevData => ({ ...prevData, [id]: value }));
  }

  const [passwordShown, setPasswordShown] = useState(false);
  
  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const dispatch = useDispatch();
  
  let [error ,setError] = useState();
  const navigate = useNavigate();
  
  const { mutate, isLoading } = useMutation(apis.signUp, {
    onError: function ({message}) {
      setError("")
      toast.error(message?.message,{id : 1}) 
    },
    onSuccess: ({ data }) => {
      if (data.status) {
       toast.success(data?.message,{id : 1})  
       return navigate(`/sign-in`)
      }
     },
  });
  
  const signUp = (e) => {
    e.preventDefault();

    // let data = {email :  data?.email , password :  data?.password}
  
    let response = errorValidate(signUpValidate(data));
    if(response === true)
    {
      mutate(data)
    }
    else{
      setError(response)
    }
  }

  useEffect(() => {
    if(user) 
      {
          navigate('/')
      }
  },[user]);

  return (
    <div className="account-section bg_img">
      <div className="container-fluid ml-3">
      <div class="account-title">
          <a href="/" class="logo">
            <img
              src={imageUrl("logo.png")}
              style={{ borderRadius: "25px", width: "200px" }}
              alt="logo"
            />
          </a>
        </div>
        <div className="account-wrapper">
          <div className="account-body">
            <div className="account-form" id="contactForm">
            <div class="head-section">
          <a href="/" class="logo">
            <img
              src={imageUrl("logo.png")}
              style={{ borderRadius: "25px", width: "200px" }}
              alt="logo"
            />
          </a>
        </div>
              <p>Create Your Account</p>
              <div className="form-group">
                <label for="name">User Name</label>
                <input
                  type="text"
                  onChange={onChange}
                  placeholder="Enter Your Name"
                  id="name"
                  name="name"
                />
                 {error?.name ? <span className="error-message">{error?.name}</span> : <></>}
                  
              </div>
              <div className="form-group">
                <label for="email">Work Email</label>
                <input
                    type="email"
                    onChange={onChange}
                    placeholder="Enter Your Email"
                  id="email"
                  name="email"
                />
                 {error?.email ? <span className="error-message">{error?.email}</span> : <></>}
                  
              </div>
              <div className="form-group">
                <label for="password">Password</label>
                <input
                 onChange={onChange}
                 type={passwordShown ? "text" : "password"}
                  placeholder="Enter Your Password"
                  id="password"
                  name="password"
                />
                <FontAwesomeIcon
                onClick={togglePassword} 
                icon={passwordShown ? faEyeSlash : faEye}
               className="form-icon input-icon position-absolute mt-3 ml-n4"
             />
              </div>
              {error?.password ? <span className="error-message mt-n5">{error?.password}</span> : <></>}
                
              <div className="form-group">
                <label for="Company">Company</label>
                <input
                  onChange={onChange}
                  type="text"
                  placeholder="Enter Company"
                  id="company"
                  name="company"
                />
  {error?.company ? <span className="error-message">{error?.company}</span> : <></>}
                  
              </div>
              <div className="form-group">
                <label for="Company_Industry">Company Industry</label>
                <input
                  onChange={onChange}
                  type="text"
                  placeholder="Enter Industry"
                  id="industry"
                  name="industry"
                />
                  {error?.industry ? <span className="error-message">{error?.industry}</span> : <></>}
                  
              </div>
              <div
                className="form-group"
                style={{ clear: "both", height: "10px" }}
              ></div>

              {/* <div className="form-group">
                <label for="allow">
                  <input
                    type="checkbox"
                    id="allow"
                    name="allow"
                    style={{ width: "10px", height: "20px" }}
                  />{" "}
                  Terms &amp; Conditions
                  <span className="required">
                    <sup>*</sup>
                  </span>
                </label>{" "}
                <a href="/privacy" >Click Here</a>
                
              </div> */}
{/* 
              <div className="form-group">
                <label for="allow">
                  <input
                    type="checkbox"
                    id="allow"
                    name="allow"
                    style={{ width: "10px", height: "20px" }}
                  />{" "}
                  Send Me Emails And Product Update
                  <span className="required">
                    <sup>*</sup>
                  </span>
                </label>{" "}
               
              </div> */}

              <div className="form-group text-center mt-n5">
                <button
                type="submit" id="submit2"
                disabled={isLoading}
                  onClick={signUp}>
                  {isLoading ? "Loading" : "Create Account"}
                </button>
              </div>
            </div>
          </div>
          <div className="account-header pb-0">
            <span className="d-block mt-15">
              Already have an account? <a href="sign-in">Sign In</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
