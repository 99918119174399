import { Card, Header } from "components";
import imageUrl from "helpers/ImageUrl";
import React from "react";
import { useLocation } from "react-router-dom";

const SelectProject = () => {
  const {state} = useLocation();
  return (
    <>   
     <Card name={state?.name} id={state?.id}/> 
    </>
  );
};

export default SelectProject;
