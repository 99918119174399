import { useQuery } from "@tanstack/react-query";
import imageUrl from "helpers/ImageUrl";
import React from "react";
import { Button, NavLink } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "redux/slices/userSlice";
import apis from "services";

const Header = () => {
  const { user } = useSelector((store) => store.user);
  const {refetch} = useQuery(['logout'], apis.logout, {enabled: false})
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
  const handleLogout = async (e) => {
    e.stopPropagation();
    await refetch()
    dispatch(logout())
    navigate("/sign-in");
}

  return (
    <>
      <header className="header">
        <div class="container">
          <div class="row">
            <div className="col">
              <a
                className="hover"
                style={{
                  fontWeight: 900,
                  fontSize: 30,
                  textDecoration: "none",
                }}
                href="/"
                class="logo mt-n1"
              >
                <img
                  src={imageUrl("logo.png")}
                  style={{ borderRadius: "25px", width: "200px" }}
                  alt="logo"
                />
                {/* Coffe Time Co */}
              </a>
            </div>

            <div className="row">
              {user ? (
                <>
                  <div className="col-6">
                    <button
                      onClick={handleLogout}
                      className="custom-button"
                    >
                      Logout
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <div className="col-6">
                    <button
                      onClick={() => navigate("/sign-in")}
                      className="custom-button"
                    >
                      Login
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      onClick={() => navigate("/sign-up")}
                      className="custom-button"
                    >
                      SignUp
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
