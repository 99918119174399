import React from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import imageUrl from "./../helpers/ImageUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import apis from "services";
import toast from 'react-hot-toast';

const UpdatePassword = () => {
  const [datas, setData] = useState({password: '', confirm_password: '' })
  let [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(apis.updatePassword, {
    onError: function ({message}) {
      toast.error(message?.message,{id:1})
    },
    onSuccess: ({ data }) => {
      if (data.status) {

        toast.success(data?.message)
        navigate('/sign-in');
      } 
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(datas,"data");
    if (datas.password === datas.confirm_password) {
      let data = { password: datas.password, ...params }
      mutate(data)
    }
    else {
      toast.error('Password confirmation not matched.')
    }
  };

  const onChange = (e) => {
    const { id, value } = e.target;
    setData(prevData => ({ ...prevData, [id]: value }));
  }
  return (
    <div class="account-section bg_img">
      <div class="container-fluid ml-3">
        <div class="account-title">
          <a href="/" class="logo">
            <img
              src={imageUrl("logo.png")}
              style={{ borderRadius: "25px", width: "200px" }}
              alt="logo"
            />
          </a>
        </div>
        <div class="account-wrapper">
          <div class="account-body">
            <form onSubmit={onSubmit}>

            <div class="account-form" id="contactForm">
            <div class="head-section">
          <a href="/" class="logo">
            <img
              src={imageUrl("logo.png")}
              style={{ borderRadius: "25px", width: "200px" }}
              alt="logo"
            />
          </a>
        </div>
              <p>Update Password</p>
              <div class="form-group">
                <label for="password">Password</label>
                <input
                 type="password"
                  placeholder="Enter New Password"
                  id="password"
                  name="password"
                  onChange={onChange}
                  minLength={8}
                  required
                />
              </div>
              <div class="form-group">
                <label for="password">Confirm Password</label>
                <input
                 type="password"
                  placeholder="Enter New Password Again"
                  id="confirm_password"
                  name="confirm_password"
                  onChange={onChange}
                  minLength={8}
                  required
                />
              </div>

              <div class="form-group text-center" id="formProgress"></div>

              <div class="form-group text-center">
                <button
                type="submit" id="submit2" class="mt-2"
                disabled={isLoading}
                  >
                  {isLoading ? "Loading" : "Update"}
                </button>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
