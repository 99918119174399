import axios from "axios";
import { useSelector } from "react-redux";

const createBackendServer = (baseURL) => {
  
  axios.defaults.withCredentials = true;
  const api = axios.create({
    baseURL: `${baseURL}/api`,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": '*'
  },
    timeout: 60 * 1000,
  });
  
  const headers = {
    "Content-Type": "multipart/form-data"
};

api.interceptors.response.use(
  (response) => response,
  (error) => {
      const message = error.response.data;
      error.message = message || error.message
      /*if(error?.response?.data?.errors)
          error.errors = error?.response?.data?.errors;*/
      return Promise.reject(error)
  })

  /***********    GET REQUESTS    **********/
  const logout = () => api.get('/auth/logout');
  
  const getOneUser = (id) => api.get(`/user/${id}`);

  const getUserPasses = (id) => api.get(`/wallet/${id}`);

  const getOnePass = (id) => api.get(`/wallet-pass/${id}`);
  

  /***********    POST REQUESTS    **********/
  
  const signUp= async (body) => api.post("/user", body);

  const createProgram= async (body) => api.post(`/program-created`, body);

  const createPass= async ({id ,body}) => api.post(`/wallet-pass/${id}`, body);

  const forget= async (body) => api.post("/user/forget", body);

  const updatePassword = async (body) => api.post("/user/update", body);

  const login = async (body) => api.post("/auth", body);

  const deletePass = async (body) => api.post("/wallet-pass", body);

  const duplicatePass = async (body) => api.post("/pass/duplicate", body);

  return {
    createProgram,
    signUp,
    login,
    logout,
    getOneUser,
    forget,
    updatePassword,
    createPass,
    getUserPasses,
    getOnePass,
    deletePass,
    duplicatePass

  };
};


const apis = createBackendServer(process.env.NODE_ENV === 'development' ? "http://localhost:5000" : "https://backendpass.herokuapp.com");


export default apis;
