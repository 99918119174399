
import { toast } from "react-hot-toast";
import useAuth from "./useAuth";

const useAuthenticate = () => {
    
  const user = useAuth();
   
    const checkAuthentication = () => {
        if(user === null) {
           toast.success("Plz Login First",{id :1})
            return ;
        }
    }


    return checkAuthentication;
    
}

export default useAuthenticate