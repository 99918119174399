import imageUrl from "helpers/ImageUrl";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";
const Card = () => {
const navigate = useNavigate()


const onPassGenricCreateHandler = () => {
  navigate("/project-name",{state : {backGroundColor : "#5eefb7", type : "genric" }})
}

const onPassLoyaltyCreateHandler = () => {
  navigate("/project-name",{state : {backGroundColor : "#121225", type : "loyalty" }})
}
const onPassEventCreateHandler = () => {
  navigate("/project-name",{state : {backGroundColor : "#37373D", type : "event_ticket"}})
}
const onPassAirlineCreateHandler = () => {
  navigate("/project-name",{state : {backGroundColor : "#328F0B", type : "boarding" }})
}
const onPassOfferCreateHandler = () => {
  navigate("/project-name",{state : {backGroundColor : "#3818BB", type : "offer" }})
}
const onPassGiftCreateHandler = () => {
  navigate("/project-name",{state : {backGroundColor : "#9DDB58", type : "giftcard" }})
}
const onPassCouponCreateHandler = () => {
  navigate("/project-name",{state : {backGroundColor : "#FFEFD2", type : "coupon" , couponHeader : "Buy one get one free",logoTexts : "Expertx",  field1 : "Blue" ,value1 : "Rocking" , 
    field2 : "AREA" , value2 : "A6" ,  field3 : "STORE.NO" , value3 : "CN21",length  : [1,2,3]}})
}
const onMembershipCreateHandler = () => {
  navigate("/project-name",{state : {backGroundColor : "#000000", type : "membership" ,couponHeader : "Buy one get one free", field1 : "Blue" ,value1 : "Rocking" , 
    field2 : "AREA" , value2 : "A6" ,  field3 : "STORE.NO" , value3 : "CN21",length  : [1,2,3]}})
}
  return (
    <>
      <DashboardHeader />
      <div className="container">
        <div
           id="main_container"
           class="pageMain_container no-background wrapper page__loading"
        >
          <div className="wrapper">
            <div className="dbContent">
              <div className="cardType">
                <div className="h templateList">
                  Choose a style to create a new Project. Please contact us if
                  you need NFC passes.
                </div>
                <div id="pass2utemplate" className="cardList mt-n5">
             {/* <div class="block" style={{ width: "10rem" }}>
                    <div className="cardPic">
                      <img
                        src={imageUrl("wallet1.jpg")}
                        alt=""
                        className="card-image"
                      />
                      <div className="action">
                        <button onClick={onPassAirlineCreateHandler} className="c-btn c-btn-cancel template">
                          Use template
                        </button>
                      </div>
                    </div>
                    <div className="cardDescript">
                      <div className="name">Boarding Pass</div>
                    </div>
                  </div> */}
                  <div class="block" style={{ width: "10rem" }}>
                    <div className="cardPic">
                      <img
                        src={imageUrl("wallet2.jpg")}
                        alt=""
                        onClick={onPassCouponCreateHandler}
                        className="card-image"
                      />
                      <div className="action">
                        <button onClick={onPassCouponCreateHandler} className="c-btn c-btn-cancel template">
                          Use template
                        </button>
                      </div>
                    </div>
                    <div className="cardDescript">
                      <div className="name">Coupon Pass</div>
                    </div>
                  </div>
                  <div class="block" style={{ width: "10rem" }}>
                    <div className="cardPic">
                      <img
                        src={imageUrl("wallet3.jpg")}
                        alt=""
                        onClick={onPassEventCreateHandler}
                        className="card-image"
                      />
                      <div className="action">
                        <button onClick={onPassEventCreateHandler} className="c-btn c-btn-cancel template">
                          Use template
                        </button>
                      </div>
                    </div>
                    <div className="cardDescript">
                      <div className="name">Event Ticket Pass</div>
                    </div>
                  </div>
                 <div class="block" style={{ width: "10rem" }}>
                    <div className="cardPic">
                      <img
                        src={imageUrl("wallet4.jpg")}
                        alt=""
                        onClick={onPassLoyaltyCreateHandler}
                        className="card-image"
                      />
                      <div className="action">
                        <button onClick={onPassLoyaltyCreateHandler} className="c-btn c-btn-cancel template">
                          Use template
                        </button>
                      </div>
                    </div>
                    <div className="cardDescript">
                      <div className="name">Loyalty Pass</div>
                    </div>
                  </div>   
                  <div class="block" style={{ width: "10rem" }}>
                    <div className="cardPic">
                      <img
                        src={imageUrl("wallet6.jpg")}
                        alt=""
                        onClick={onPassGenricCreateHandler}
                        className="card-image"
                      />
                      <div className="action">
                        <button onClick={onPassGenricCreateHandler} className="c-btn c-btn-cancel template">
                          Use template
                        </button>
                      </div>
                    </div>
                    <div className="cardDescript">
                      <div className="name">Generic Pass</div>
                    </div>
                  </div>
                  <div class="block" style={{ width: "10rem" }}>
                    <div className="cardPic">
                      <img
                        src={imageUrl("membership.jpg")}
                        alt=""
                        onClick={onMembershipCreateHandler}
                        className="card-image"
                      />
                      <div className="action">
                        <button onClick={onMembershipCreateHandler} className="c-btn c-btn-cancel template">
                          Use template
                        </button>
                      </div>
                    </div>
                    <div className="cardDescript">
                      <div className="name">Memebership Pass</div>
                    </div>
                  </div>
               <div class="block" style={{ width: "10rem" }}>
                    <div className="cardPic">
                      <img
                        src={imageUrl("wallet7.jpg")}
                        alt=""
                        onClick={onPassGiftCreateHandler}
                        className="card-image"
                      />
                      <div className="action">
                        <button onClick={onPassGiftCreateHandler} className="c-btn c-btn-cancel template">
                          Use template
                        </button>
                      </div>
                    </div>
                    <div className="cardDescript">
                      <div className="name">Gift Card Pass</div>
                    </div>
                  </div>
              
                  <div class="block" style={{ width: "10rem" }}>
                    <div className="cardPic">
                      <img
                        src={imageUrl("wallet8.jpg")}
                        alt=""
                        onClick={onPassOfferCreateHandler}
                        className="card-image"
                      />
                      <div className="action">
                        <button onClick={onPassOfferCreateHandler} className="c-btn c-btn-cancel template">
                          Use template
                        </button> 
                      </div>
                    </div>
                    <div className="cardDescript">
                      <div className="name">Offer Pass</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
