import Joi from "joi";

export const signUpValidate = (req) => {
    const schema = Joi.object({
      name : Joi.string().min(8).required(),
      email: Joi.string().email({ tlds: { allow: false } }).required(),
      password: Joi.string().min(8).max(255).required(),
      company : Joi.string().required(),
      industry : Joi.string().required(),
    })
      return schema.validate(req);
  }