import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row, Modal, Button } from "react-bootstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import apis from "services";
import { toast } from "react-hot-toast";
import useAuth from "components/hooks/useAuth";
import useAuthenticate from "components/hooks/useAuthenticate";

const DeleteProjectModal = ({
  id,
  name,
  type,
  status,
  toggleModalClose,
  refetch,
}) => {
  const authenticate = useAuthenticate();
  const user = useAuth();

  let [formData, setFormData] = useState({
    id: id,
    name: name,
    type: type,
  });

  const {data, isLoading : isLoadingData,refetch : refetchPass , isRefetching} = useQuery(['getUser'], () => apis.getOnePass(id),{
    onError: function ({message}) {
      console.log(message.message);
    },
    onSuccess: (data) => {
       let wallet = data?.data?.wallet
       
      let { _id ,url ,createdAt ,updatedAt,wallet_Id,__v ,isActive, ...restData } = wallet;
     console.log(wallet.type,wallet?.name , wallet?.wallet_Id,"Wallet")
     
      setFormData({type:wallet.type,name : wallet?.name ,id: wallet?.wallet_Id});
      }
  })


  useEffect(() => {
    setFormData({ id: id, name: name, type: type });
    refetchPass();
  }, [id]);

  const { mutate, isLoading } = useMutation(apis.deletePass, {
    onError: function ({ message }) {
      toast.error(message?.message, { id: 1 });
    },
    onSuccess: ({ data }) => {
      if (data.status) {
        console.log(data?.message, "data");
        toast.success(data?.message, { id: 1 });
        refetch();
        toggleModalClose();
      }
    },
  });

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (user === null) {
      authenticate();
    } else {
    //   const data = { id, name, type };
      mutate(formData);
    }
  };

  return (
    <>
      <Modal
        style={{ marginTop: "99px" }}
        show={status}
        onHide={toggleModalClose}
      >
        <Form onSubmit={(e) => onSubmitHandler(e)}>
          <Modal.Header>
            <h2>Are You Sure You Want To Delete Project ?</h2>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <div className="signup-text">
                  <div className="form-group signup-box mb-3">
                    <label
                      htmlFor="name"
                      className="custom-label"
                      style={{ marginLeft: 0 }}
                    >
                      Project Name
                    </label>
                    <input
                      type="text"
                      className="form-control login-form"
                      placeholder="Name"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      required
                      id="name"
                      name="name"
                      disabled
                      value={name}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            
            <button type='submit' disabled={isLoading || isRefetching} className='btn-danger'>{ isLoading || isRefetching ? "Loading" : "Delete"}</button>
                        
            {/* <button className='custom-button' onClick={toggleModalClose}>Close</button> */}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default DeleteProjectModal;
