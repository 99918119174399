import { DashboardHeader, Sidebar } from "components";
import imageUrl from "helpers/ImageUrl";
import React from "react";
const DesignProjectNew = () => {
    const handleChangeImage = (e) => {
        if (e.target.files[0]) {
        //   setFormData((prevState) => ({ ...prevState, image: e.target.files[0] }));
        //   setPreview({ [e.target.name]: URL.createObjectURL(e.target.files[0]) });
        }
      };
  return (
    <>
      <div className="main-wrapper">
        {/* <DashboardHeader /> */}
        <Sidebar />
        <div className="content">
          <div className="pass-main">
            <div className="pass-wrapper">
              <span className="active-passtype">
                <img src={imageUrl("icon-edit.png")} alt="logo" />
              </span>
              <span>
                <img src={imageUrl("icon-color.png")} alt="logo" />
              </span>
              <span>
                <img src={imageUrl("icon-qrcode.png")} alt="logo" />
              </span>
            </div>
            <div className="pass-wrapper pass-type active-passtype">
              <button className="passtype-btn">
                <i class="fa-brands fa-google"></i>
                Google Wallet
              </button>
              <img
                style={{ padding: "10px" }}
                src={imageUrl("line.png")}
                alt="logo"
              />

              <button className="passtype-btn">
                <i class="fa-brands fa-apple"></i>
                Apple Wallet
              </button>
            </div>
          </div>

          <div className="pass-editing">
            <div className="pass-tabs">
              <div className="pass-design-top">
                <img src={imageUrl("edit.png")} alt="logo" />
                <span className="pass1">Add Fields</span>
              </div>
              <div className="pass-design-top"></div>
              <div className="pass-design-top">
                <img src={imageUrl("close.png")} alt="logo" />
              </div>
            </div>
            <div className="color-theme-wrapper">
              <p style={{ fontSize: "25px" }}>Logo</p>
              <p className="css-1xroguk">
                A logo is required for a wallet pass. The recommended dimensions
                are 660 by 660.
              </p>
              <div className="logo-upload">
                <img src={imageUrl("passlogo.png")} alt="" />
                <input
                  type="file"
                  class="form-control login-form input-pl-0"
                  accept=".jpeg, .jpg, .png"
                  name="image"
                  id="image"
                  onChange={(e) => handleChangeImage(e)}
                />
              </div>
            </div>

            {/* new */}

            <div className="passBox" style={{ marginTop: "-360px" }}>
              <div id="pass-barcode-type" className="qr">
                <p className="pass1" style={{ marginLeft: "110px" }}>
                  Card Front
                </p>

                <div
                  className="passFront mt-2"
                  style={{
                    //   color: `${getContrastYIQ(formData?.sketchPickerColor)}`,
                    height: "420px",
                    fontSize: "15px",
                    fontWeight: 700,
                    borderRadius: "25px",
                    backgroundColor: `white`,
                  }}
                >
                  <div className="logo-pass">
                    {/* <img
                            src={
                              preview["image"] !== undefined
                                ? preview["image"]
                                : imageUrl("passlogo.png")
                            }
                            alt=""
                          />{" "}
                          {formData.logoTexts} */}
                  </div>

                  <div className="pass-auxiliary">
                    <div
                      id="pass-auxiliary-field"
                      className="pass-field"

                      // style={{ border: "2px solid grey" }}
                    >
                      <div
                        id="a1"
                        className="alignZone"
                        style={{
                          textAlign: "left",
                          margin: "4px",
                          display: "inline-block",
                        }}
                      >
                        <div className="labels">
                          <span
                            className="label"
                            style={
                              {
                                // color: `${getContrastYIQ(formData?.sketchPickerColor)}`,
                              }
                            }
                          >
                            {/* {formData.field1} */}
                          </span>
                        </div>
                        <div className="values">
                          <span
                            className="foreground"
                            style={
                              {
                                // color: `${getContrastYIQ(formData?.sketchPickerColor)}`,
                              }
                            }
                          >
                            {/* {formData.value1} */}
                          </span>
                        </div>
                      </div>

                      <div
                        id="a2"
                        className="alignZone"
                        style={{
                          textAlign: "left",
                          margin: "4px",
                          display: "inline-block",
                        }}
                      >
                        <div className="labels">
                          <span
                            className="label"
                            style={
                              {
                                // color: `${getContrastYIQ(formData?.sketchPickerColor)}`,
                              }
                            }
                          >
                            {/* {formData.field2} */}
                          </span>
                        </div>
                        <div className="values">
                          <span
                            className="foreground"
                            style={
                              {
                                // color: `${getContrastYIQ(formData?.sketchPickerColor)}`,
                              }
                            }
                          >
                            {/* {formData.value2} */}
                          </span>
                        </div>
                      </div>
                      <div
                        id="a3"
                        className="alignZone"
                        style={{
                          textAlign: "left",
                          margin: "4px",
                          display: "inline-block",
                        }}
                      >
                        <div className="labels">
                          <span
                            className="label"
                            style={
                              {
                                // color: `${getContrastYIQ(formData?.sketchPickerColor)}`,
                              }
                            }
                          >
                            {/* {formData.field3} */}
                          </span>
                        </div>
                        <div className="values">
                          <span
                            className="foreground"
                            style={
                              {
                                // color: `${getContrastYIQ(formData?.sketchPickerColor)}`,
                              }
                            }
                          >
                            {/* {formData.value3} */}
                          </span>
                        </div>
                      </div>
                      <div
                        id="a4"
                        className="alignZone"
                        style={{
                          textAlign: "left",
                          margin: "4px",
                          display: "inline-block",
                        }}
                      >
                        <div className="labels">
                          <span
                            className="label"
                            style={
                              {
                                // color: `${getContrastYIQ(formData?.sketchPickerColor)}`,
                              }
                            }
                          >
                            {/* {formData.field4} */}
                          </span>
                        </div>
                        <div className="values">
                          <span
                            className="foreground"
                            style={
                              {
                                // color: `${getContrastYIQ(formData?.sketchPickerColor)}`,
                              }
                            }
                          >
                            {/* {formData.value4} */}
                          </span>
                        </div>
                      </div>
                      <div
                        id="a5"
                        className="alignZone"
                        style={{
                          textAlign: "left",
                          margin: "4px",
                          display: "inline-block",
                        }}
                      >
                        <div className="labels">
                          <span
                            className="label"
                            style={
                              {
                                // color: `${getContrastYIQ(formData?.sketchPickerColor)}`,
                              }
                            }
                          >
                            {/* {formData.field5} */}
                          </span>
                        </div>
                        <div className="values">
                          <span
                            className="foreground"
                            style={
                              {
                                // color: `${getContrastYIQ(formData?.sketchPickerColor)}`,
                              }
                            }
                          >
                            {/* {formData.value5} */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="passlogo">
                    {/* <img
                            src={imageUrl(barcodePreview.image)}
                            alt=""
                            className={
                              modal || modalFont || modalGround
                                ? "hide"
                                : "qrcode-pass"
                            }
                          /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DesignProjectNew;
