import React from "react";
const ProfileDetails = () => {
  return (
    <>
       <h1>Profile Details</h1>
    </>
  );
};

export default ProfileDetails;
