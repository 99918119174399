import React from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import imageUrl from "./../helpers/ImageUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import apis from "../services";
import { loginValidate } from "../validation/loginValidation";
import { errorValidate } from "../validation/errorHandleJoi";
import toast from "react-hot-toast";
import { setUser } from "redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const Signin = () => {
  const { user } = useSelector((store) => store.user);
  let [data, setData] = useState({ email: "", password: "" });
  const onChange = (e) => {
    const { id, value } = e.target;
    setData((prevData) => ({ ...prevData, [id]: value }));
  };

  const [passwordShown, setPasswordShown] = useState(false);

  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const dispatch = useDispatch();

  let [error, setError] = useState();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(apis.login, {
    onError: function ({ message }) {
      setError("");
      console.log(message);
      toast.error(message, { id: 1 });
    },
    onSuccess: ({ data: user, status, headers }) => {
      if (status === 200) {
        const token = headers["x-auth-token"];
        dispatch(setUser({ ...user?.user, token }));
        toast.success(user?.message, { id: 1 });
        navigate("/");
      }
    },
  });

  const signIn = (e) => {
    e.preventDefault();

    // let data = {email :  data?.email , password :  data?.password}

    let response = errorValidate(loginValidate(data));
    if (response === true) {
      mutate(data);
    } else {
      setError(response);
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  return (
    <div class="account-section bg_img">
      <div class="container-fluid ml-3">
        <div class="account-title">
          <a href="/" class="logo">
            <img
              src={imageUrl("logo.png")}
              style={{ borderRadius: "25px", width: "200px" }}
              alt="logo"
            />
          </a>
        </div>

        <div class="account-wrapper">
          <div class="account-body">
            <div class="account-form" id="contactForm">
              <div class="head-section">
                <a href="/" class="logo">
                  <img
                    src={imageUrl("logo.png")}
                    style={{ borderRadius: "25px", width: "200px" }}
                    alt="logo"
                  />
                </a>
              </div>
              <p>Account Login</p>

              <div class="form-group">
                <label for="email">Email </label>
                <input
                  type="email"
                  onChange={onChange}
                  id="email"
                  placeholder="Enter Your Email"
                  name="email"
                />
                {error?.email ? (
                  <span className="error-message">{error?.email}</span>
                ) : (
                  <></>
                )}
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input
                  type={passwordShown ? "text" : "password"}
                  placeholder="Enter Your Password"
                  id="password"
                  name="password"
                  onChange={onChange}
                />
                <FontAwesomeIcon
                  onClick={togglePassword}
                  icon={passwordShown ? faEyeSlash : faEye}
                  className="form-icon input-icon position-absolute mt-3 ml-n4"
                />
              </div>
              {error?.password ? (
                <span className="error-message">{error?.password}</span>
              ) : (
                <></>
              )}

              <div class="form-group text-center" id="formProgress"></div>

              <div class="form-group text-center">
                {/* <button onClick={signIn} type="submit" class="mt-2 mb-2">
                  Log In
                </button>
                */}
                <button
                disabled={isLoading}
                  onClick={signIn}>
                  {isLoading ? "Loading" : "Log In"}
                </button>
              </div>
            </div>
          </div>

          <div class="account-header pb-0">
            <span
              onClick={() => navigate("/forget")}
              class="d-block mt-15 cursor"
              style={{ color: "blue" }}
            >
              Forgot your password?{" "}
            </span>
            <span class="d-block mt-15">
              Don't have an account? <a href="sign-up">Sign Up</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
