import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Col, Form, Row, Modal, Button} from "react-bootstrap";
import { useMutation, useQuery } from '@tanstack/react-query';
import apis from 'services';
import { toast } from 'react-hot-toast';
import useAuth from 'components/hooks/useAuth';
import useAuthenticate from 'components/hooks/useAuthenticate';

const DuplicateProjectModal = ({id , description,status,toggleModalClose,refetch}) => {


let [formData, setFormData] = useState({
    id:id,
    description : description,
    name :'',
    sketchPickerColor: '',
    fieldLength : '',
    type: '',
    logoTexts: "",
    barcode: '',
    image: '',
    heroImage: '',
    couponHeader: '',
    field1: "",
    value1: "",
    field2: "",
    value2: "",
    field3: "",
    value3: "",
    field4: "",
    value4: "",
    field5: "",
    value5: "",
    field6: "",
    value6: "",
  });

    const authenticate = useAuthenticate();
    const user = useAuth();
    console.log(id,"ID");
    
    const {data, isLoading : isLoadingData,refetch : refetchPass , isRefetching} = useQuery(['getUser'], () => apis.getOnePass(id),{
        onError: function ({message}) {
          console.log(message.message);
        },
        onSuccess: (data) => {
           console.log(data?.data?.wallet,"DATA");
           let wallet = data?.data?.wallet
           
          let { _id ,url ,createdAt ,updatedAt,wallet_Id,__v ,isActive, ...restData } = wallet;
            setFormData({...restData,id:id,description : description});
          }
      })


      console.log(formData,"NEW");

      // useEffect(()=>{
      //   setFormData({id:id,description : description})
      //   refetchPass();
      // },[id])
    

    const { mutate, isLoading } = useMutation(apis.duplicatePass, {
        onError: function ({message}) {
          toast.error(message?.message, { id: 1 });
        },
        onSuccess: ({ data }) => {
          if (data.status) {
            console.log(data?.message, "data");
            toast.success(data?.message, { id: 1 });
            refetch();
            toggleModalClose();
          }
        },
      });

      
  const onChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

    const onSubmitHandler = (e) => {
        e.preventDefault();
        if (user === null) {
            authenticate()
         }
        else
        {
            console.log(formData)
            mutate(formData);
        }
    }

    return (
        <>
            <Modal style ={{marginTop : "99px"}} show={status} onHide={toggleModalClose}>
                <Form onSubmit={(e)=>onSubmitHandler(e)}>
                    <Modal.Header>
                        <h2>Duplicate Project</h2>
                    </Modal.Header>
                    <Modal.Body>
                      <Row>
                            <Col>
                                <div className="signup-text">
                                    <div className="form-group signup-box mb-3">
                                        <label htmlFor="name" className="custom-label"
                                               style={{marginLeft: 0}}>Project Name</label>
                                        <input
                                            type="text"
                                            className="form-control login-form"
                                            placeholder="Name"
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            required
                                            id="name"
                                            onChange={onChange}
                                            name="name"
                                            // value={formData.name}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <button type='submit' disabled={isLoading || isRefetching} className='btn-primary'>{ isLoading || isRefetching ? "Loading" : "Duplicate"}</button>
                        {/* <button className='custom-button' onClick={toggleModalClose}>Close</button> */}
                      
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default DuplicateProjectModal;