import { DashboardHeader } from "components";
import imageUrl from "helpers/ImageUrl";
import React from "react";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";
const IssueSetting = () => {
  const { state } = useLocation();
  console.log(state?.url, "vlue");
  let url = "https://pay.google.com/gp/v/save/eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJkZXBhc3MtZ29vZ2xlLXBhc3Mtc2VydmljZS1hY2NAZGVwYXNzMjAyMi5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsImF1ZCI6Imdvb2dsZSIsIm9yaWdpbnMiOlsiaHR0cDovL2xvY2FsaG9zdDo4MDgwIl0sInR5cCI6InNhdmV0b3dhbGxldCIsInBheWxvYWQiOnsiZ2VuZXJpY09iamVjdHMiOlt7ImlkIjoiMzM4ODAwMDAwMDAyMjE4ODQ1Mi5zYWFkMTIzNDU2NzI4In1dfSwiaWF0IjoxNjc0NTUyNzIzfQ.TttzUVC3E_n3hqT7-_sJYxgjKj0oMiCmFY9lJEeBvsxLNpItmLcTr4kOE9U_f45j2xPrmIUhGLbsI0AYjSYO7loWpUdfyrTiRCWCM_KWjJroqgMYbO5XnMAEbZVpXtehZVbXRq_KTKhu-C91vdINzp5MtWM198aOYBHsi8zJmiOqW7CrYjztnkEN6C-aO6TrOFm0VAvlxngHV0xBzvK-edl-r7z7jq-TRxk5WLZlKFfcyuGLuTOh9p6tsbhxH6Vl2bICWZ1yEUVfovkxSnYMsaAn6sV1-xSTezwz9bTOeIy0BZvX3JyuVKpw6WyDe1tVmqYWBH1hAN7LFHwdXA9_aA"
  return (
    <>
      <DashboardHeader />
      <div
        id="main_container"
        // class="wrapper-card-body"
        class="wrapper pageMain_container"
      >
        <div className="mainContent mt-5">
          <div className="rbox-contentBox" style={{ top: "60px", padding: 0 }}>
            <div className="rbox-headerBox 1-alignCenter">
              <h1 className="stepTitle" style={{ textAlign: "center" }}>
                Scan Qr Code To Add In Google Wallet
              </h1>
              <div
                style={{
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: 294,
                  width: "100%",
                }}
              >
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={state?.url}
                  viewBox={`0 0 256 256`}
                />
                <img className="m-5" src={imageUrl("wallet_button.png")} alt="" onClick={() => {
                        window.open(state?.url, "_blank");
                      }}/>
              </div>
              
            </div>
            
            
          </div>
        </div>
      </div>
    </>
  );
};

export default IssueSetting;
