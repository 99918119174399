import imageUrl from "helpers/ImageUrl";
import React from "react";
import { Button, NavLink } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const DashboardHeader = () => {
  const navigate = useNavigate()
  return (
    <>
      <header className="header">
        <div class="container">
          <div class="row">
            <div className="col">
              <a className="hover" style={{fontWeight : 900 , fontSize : 30 , textDecoration : "none" }}
                   href="/" class="logo mt-n1">
                <img
                  src={imageUrl("logo.png")}
                  style={{ borderRadius: "25px", width: "200px" }}
                  alt="logo"
                />
                {/* Coffe Time Co */}
              </a>
            </div>
            <ul className="headerStepBox steps" id="headerSteps"></ul>
                <ul className="headerItems mt-n2 mr-5">
                  <li className="headerItem headerAction ">
                    <a className="hover" href="/dashboard">Dashboard</a>
                  </li>
                  <li className="headerItem headerAction">
                    <a  className="hover" href="/dashboard">User Guide</a>
                  </li>
                  <li className="headerItem headerAction">
                    <a  className="hover" href="/dashboard">Purchase</a>
                  </li>
                </ul>
              
            {/* <div className="row">
            <div className="col-6"><button onClick={()=>(navigate("/sign-in"))} className="custom-button">Login</button></div>
            <div className="col-6"><button onClick={()=>(navigate("/sign-up"))}className="custom-button">SignUp</button></div>
            </div> */}
          </div>
        </div>
      </header> 
    </>
  );
};

export default DashboardHeader;
