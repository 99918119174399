import { DashboardHeader, DynamicFields } from "components";
import imageUrl from "helpers/ImageUrl";
import React from "react";
import { useState } from "react";
import {
  redirect,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { SketchPicker } from "react-color";
import { useMutation, useQuery } from "@tanstack/react-query";
import apis from "../services";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { LOGO, HEROIMAGE } from "components/assests/constant";
import { logout } from "redux/slices/userSlice";
import { useEffect } from "react";

import axios from "axios";
import useAuth from "components/hooks/useAuth";

const DesignProject = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const user = useAuth();

  const { state } = useLocation();

  useEffect(() => {
    if (!state && !id) {
      navigate("/");
    }
  }, []);

  let [formData, setFormData] = useState({
    name: state?.name || "",
    description: state?.description,
    sketchPickerColor: state?.backGroundColor || "#ffff",
    fieldLength: "",
    type: state?.type,
    logoTexts: state?.logoTexts || "",
    barcode: "QR_CODE",
    image: LOGO,
    heroImage: HEROIMAGE,
    couponHeader: state?.couponHeader || "",
    field1: state?.field1 || "",
    value1: state?.value1 || "",
    field2: state?.field2 || "",
    value2: state?.value2 || "",
    field3: state?.field3 || "",
    value3: state?.value3 || "",
    field4: state?.field4 || "",
    value4: state?.value4 || "",
    field5: state?.field5 || "",
    value5: state?.value5 || "",
    field6: state?.field6 || "",
    value6: state?.value6 || "",
  });

  const { data, refetch } = useQuery(
    ["getUserWalletPass"],
    () => apis.getOnePass(id),
    {
      onSuccess: (data) => {
        const wallet = data?.data?.wallet || [];
        let {
          _id,
          url,
          createdAt,
          updatedAt,
          wallet_Id,
          __v,
          userId,
          isActive,
          ...restData
        } = wallet;
        console.log(restData, "wallet");
        setFormData((prevState) => ({ ...prevState, ...restData }));

        setArray(wallet?.fieldLength);
        console.log(formData, "wallet");
      },
    }
  );

  console.log(formData, "STATE");
  let [theArray, setArray] = useState([]);

  // useEffect(() => {

  //   if (id) {
  //     refetch();
  //   }

  // }, []);

  useEffect(() => {
    if (state?.type === "coupon" || state?.type === "membership") {
      console.log(state);
      setArray(state?.length);
    }
  }, []);

  let lastArrayValue = 0;
  lastArrayValue = theArray[theArray?.length - 1];

  let onAddFieldHandler = () => {
    if (theArray.length > 0) {
      setArray(theArray.sort());
      setArray((prevState) => [...prevState, lastArrayValue + 1]);
    } else {
      setArray([1]);
    }
  };

  let onRemoveFieldHandler = (id) => {
    setArray(theArray.filter((_id) => _id !== id));
    setFormData((prevState) => ({
      ...prevState,
      [`field${id}`]: "",
      [`value${id}`]: "",
    }));
  };

  const [preview, setPreview] = useState("logo");
  const [previewHero, setPreviewHero] = useState("hero");

  const [barcodePreview, setBarcodePreview] = useState({
    image: "barcode_1.png",
    type: 4,
    value: "QR_CODE",
  });

  const BarcodeHandler = (image, type, value) => {
    setBarcodePreview({ image: image, type: type, value: value });
    setFormData((prevState) => ({ ...prevState, barcode: value }));
  };

  console.log(formData, "form");

  const { mutate, isLoading } = useMutation(apis.createPass, {
    onError: function ({ message }) {
      toast.error(message?.message, { id: 1 });
    },
    onSuccess: ({ data }) => {
      if (data.status) {
        console.log(data?.message, "data");
        toast.success(data?.message, { id: 1 });
        return navigate("/issue-setting", { state: { url: data?.url } });
      }
    },
  });

  function getContrastYIQ(hexcolor) {
    var r = parseInt(hexcolor.substring(1, 3), 16);
    var g = parseInt(hexcolor.substring(3, 5), 16);
    var b = parseInt(hexcolor.substring(5, 7), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "black" : "white";
  }

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal((prevState) => !prevState);
  };
  const toggleClose = () => {
    setModal(false);
  };

  const onChangeHandler = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleChangeImage = (e) => {
    if (e.target.files[0]) {
      setFormData((prevState) => ({ ...prevState, image: e.target.files[0] }));

      setPreview({ [e.target.name]: URL.createObjectURL(e.target.files[0]) });
    }
  };

  const handleHeroChangeImage = (e) => {
    if (e.target.files[0]) {
      setFormData((prevState) => ({
        ...prevState,
        heroImage: e.target.files[0],
      }));

      setPreviewHero({
        [e.target.name]: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const onSubmitHandler = () => {
    console.log(theArray);
    setFormData((prevState) => ({
      ...prevState,
      barcode: barcodePreview.value,
      fieldLength: [...theArray],
    }));

    const form_data = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      if (key === "fieldLength") {
        form_data.append("fieldLength[]", value);
      } else {
        form_data.append(key, value);
      }
      // form_data.append(key, [value]);
    }

    console.log(form_data, "a");
    if (id) {
      toast.success("COMING SOON",{id:1});
    } else {
      mutate({ id: user._id, body: form_data });
    }
  };
  return (
    <>
      <DashboardHeader />

      <div className="container-fluid">
        <div className="mainContent mt-5">
          <div className="wrapper designer-outer" onDoubleClick={toggleClose}>
            <div className="row">
              <div className="col">
                <div
                  className="rbox mt-n1"
                  style={{
                    backgroundColor: "white",
                    width: "810px",
                    height: "500px",
                    margin: "20px",
                    border: "2px solid lightgrey",
                  }}
                >
                  <div className="rbox-header">
                    <ul className="dsul">
                      <li id="designPass">Design</li>
                    </ul>
                  </div>

                  <div className="rbox-content">
                    <div className="rbox-frame">
                      <div className="outer-box">
                        <div className="input-box mt-3">
                          <div class="row">
                            <div class="col ml-4">
                              Program Name
                              <input
                                type="text"
                                id="name"
                                class="form-control"
                                maxLength={18}
                                value={formData?.name}
                                onChange={onChangeHandler}
                              />
                            </div>
                            <div class="col ml-4">
                              Logo Text
                              <input
                                type="text"
                                id="logoTexts"
                                class="form-control"
                                maxLength={18}
                                value={formData?.logoTexts}
                                onChange={onChangeHandler}
                              />
                            </div>
                            <div class="col ml-4 mt-2">
                              Logo Image
                              <input
                                type="file"
                                class="form-control login-form input-pl-0"
                                accept=".jpeg, .jpg, .png"
                                name="image"
                                id="image"
                                onChange={(e) => handleChangeImage(e)}
                              />
                            </div>
                            <div class="col ml-4 mt-2">
                              Hero Image
                              <input
                                type="file"
                                class="form-control login-form input-pl-0"
                                accept=".jpeg, .jpg, .png"
                                name="heroImage"
                                id="heroImage"
                                onChange={(e) => handleHeroChangeImage(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="input-box mt-3">
                        <div className="input-1 ml-4">Data Format</div>
                        <div className="input-r">
                          <ul className="barcode_ul" id="barcode_ul">
                            <li>
                              <img
                                src={imageUrl("barcode_1.png")}
                                alt=""
                                className={
                                  barcodePreview.type === 4
                                    ? "active-barcode"
                                    : ""
                                }
                                onClick={() =>
                                  BarcodeHandler("barcode_1.png", 4, "QR_CODE")
                                }
                              />
                              <div className="barcode_v">QR Code</div>
                            </li>
                            <li>
                              <img
                                src={imageUrl("barcode_4.png")}
                                alt=""
                                className={
                                  barcodePreview.type === 1
                                    ? "active-barcode"
                                    : ""
                                }
                                onClick={() =>
                                  BarcodeHandler("barcode_4.png", 1, "AZTEC")
                                }
                              />
                              <div className="barcode_v">Aztec</div>
                            </li>
                            <li>
                              <img
                                src={imageUrl("barcode_3.png")}
                                alt=""
                                className={
                                  barcodePreview.type === 3
                                    ? "active-barcode"
                                    : ""
                                }
                                onClick={() =>
                                  BarcodeHandler("barcode_3.png", 3, "PDF_417")
                                }
                              />
                              <div className="barcode_v">PDF417</div>
                            </li>
                            <li>
                              <img
                                src={imageUrl("barcode_2.png")}
                                alt=""
                                className={
                                  barcodePreview.type === 2
                                    ? "active-barcode"
                                    : ""
                                }
                                onClick={() =>
                                  BarcodeHandler("barcode_2.png", 2, "CODE_128")
                                }
                              />
                              <div className="barcode_v">Code 128</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {state?.type === "coupon" ||
                      state?.type === "genric" ||
                      formData?.type === "coupon" ||
                      formData?.type === "genric" ? (
                        <div class="row mb-5 mt-n3">
                          <div class="col ml-4">
                            Header
                            <input
                              type="text"
                              id="couponHeader"
                              class="form-control"
                              maxLength={22}
                              required
                              value={formData?.couponHeader}
                              onChange={onChangeHandler}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <section className="ac-container mt-n4">
                        {theArray?.length === 6 ? (
                          <></>
                        ) : (
                          <>
                            <button
                              onClick={onAddFieldHandler}
                              className="custom-button"
                              style={{ width: "150px", margin: "20px" }}
                            >
                              Add Fields ({6 - theArray.length})
                            </button>
                          </>
                        )}

                        {theArray?.map((item, index) => {
                          return (
                            <>
                              <div className="field_box mt-4">
                                <div className="field_h">
                                  <span className="field_h_title">
                                    Field {index + 1}
                                  </span>
                                  <div className="field_h_fieldControll">
                                    <div
                                      className="mt-2 cursor"
                                      onClick={() =>
                                        onRemoveFieldHandler(index + 1)
                                      }
                                    >
                                      Delete
                                    </div>
                                  </div>
                                </div>
                                <div className="field_b">
                                  <div className="input-box mt-3">
                                    <div className="input-1 mr-2">Label</div>
                                    <div className="input-1 mr-2">
                                      <input
                                        type="text"
                                        name={`field${index + 1}`}
                                        id={`field${index + 1}`}
                                        maxLength={13}
                                        value={`${
                                          formData?.[`field${index + 1}`]
                                        }`}
                                        onChange={onChangeHandler}
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="input-1 mr-2">Value</div>
                                    <div className="input-1">
                                      <input
                                        type="text"
                                        name={`value${index + 1}`}
                                        id={`value${index + 1}`}
                                        maxLength={13}
                                        value={`${
                                          formData?.[`value${index + 1}`]
                                        }`}
                                        onChange={onChangeHandler}
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </section>
                    </div>
                  </div>
                  <div className="rbox-footer">
                    {/* <button
                      onClick={() => navigate("/")}
                      className="custom-button"
                      style={{ width: "200px", margin: "20px" }}
                    >
                      Save as draft
                    </button>
       */}
                    <button
                      className="custom-button ml-5"
                      disabled={isLoading || formData?.type === undefined}
                      onClick={onSubmitHandler}
                    >
                      {isLoading || formData?.type === undefined
                        ? "Loading"
                        : "Create"}
                    </button>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="1boxCoupon mt-n2">
                  <div className="colorBox" style={{ visibility: "visible" }}>
                    <ul id="colorList">
                      <li>
                        <div className="color_h">Background</div>
                        <div
                          onClick={toggleModal}
                          className="color_b"
                          style={{
                            backgroundColor: `${formData?.sketchPickerColor}`,
                          }}
                        ></div>
                      </li>
                    </ul>
                  </div>
                  <section className="passBox">
                    <div className="color-picker">
                      {modal ? (
                        <SketchPicker
                          onChange={(color) => {
                            setFormData((prevState) => ({
                              ...prevState,
                              sketchPickerColor: color.hex,
                            }));
                          }}
                          color={formData.sketchPickerColor}
                        />
                      ) : (
                        <></>
                      )}
                    </div>

                    <div
                      className="passBox-wrapper mt-2"
                      style={{
                        color: `${getContrastYIQ(formData?.sketchPickerColor)}`,
                        backgroundColor: `${formData?.sketchPickerColor}`,
                      }}
                    >
                      <div className="logo-pass">
                        <img
                          src={
                            preview["image"] !== undefined
                              ? preview["image"]
                              : LOGO
                          }
                          alt=""
                        />{" "}
                        <span className="mt-3">Privatyze</span>
                        <span style={{ overflow: "hidden" }}>
                          {formData?.flightNumber}
                        </span>
                      </div>
                      <div className="ml-3 mt-3">
                        <h5>
                          {state?.type === "coupon" ||
                          state?.type === "genric" ||
                          formData?.type === "coupon" ||
                          formData?.type === "genric"
                            ? formData?.couponHeader
                            : ""}
                        </h5>

                        <h3>
                          {state?.type === "giftcard"
                            ? "Gift Card"
                            : formData.logoTexts}
                        </h3>
                      </div>

                      <div className="main-dynamic-container">
                        <div className="dynamic-fields">
                          <div>{formData.field1}</div>
                          <div>{formData.field2}</div>
                          <div>{formData.field3}</div>
                          <div>{formData.value1}</div>
                          <div>{formData.value2}</div>
                          <div>{formData.value3}</div>
                        </div>
                      </div>

                      <div className="main-dynamic-container">
                        <div className="dynamic-fields">
                          <div>{formData.field4}</div>
                          <div>{formData.field5}</div>
                          <div>{formData.field6}</div>
                          <div>{formData.value4}</div>
                          <div>{formData.value5}</div>
                          <div>{formData.value6}</div>
                        </div>
                      </div>

                      <div className="passlogo">
                        <img
                          src={imageUrl(barcodePreview.image)}
                          alt=""
                          className="qrcode-pass"
                        />
                        <img
                          src={
                            previewHero["heroImage"] !== undefined
                              ? previewHero["heroImage"]
                              : HEROIMAGE
                          }
                          alt=""
                          className="heroImage"
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DesignProject;
