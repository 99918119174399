import imageUrl from 'helpers/ImageUrl'
import React from 'react'

const Sidebar = () => {
  return (
  <>
   <div class="sidebar">
          <div className="sidebar-logo">
            <img className="" src={imageUrl("sidebar-logo.png")} alt="logo" />
          </div>
          <a class="active" href="#home">
            <i class="fas fa-project-diagram"></i>Projects
          </a>
        </div>
  </>
  )
}

export default Sidebar
