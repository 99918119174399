import React from "react";
import imageUrl from "./../helpers/ImageUrl";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import apis from "../services";
import { forgetValidate } from "../validation/forgetValidation";
import { errorValidate } from "../validation/errorHandleJoi";
import toast from 'react-hot-toast';
import { setUser } from "redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const ForgetPassword = () => {
  
  const {user} = useSelector(store => store.user);
  let [data, setData] = useState({email: ''})
  const onChange = (e) => {
    const { id, value } = e.target;
    setData(prevData => ({ ...prevData, [id]: value }));
  }

  let [error ,setError] = useState();
  const navigate = useNavigate();
  
  const { mutate, isLoading } = useMutation(apis.forget, {
    onError: function ({message}) {
      setError("")
      console.log(message?.message,"error")
      toast.error(message?.message,{id : 1}) 
    },
    onSuccess: ({ data }) => {
      if (data.status) {
       toast.success(data?.message,{id : 1})  
      }
     },
  });
  
  const onSubmitHandler = (e) => {
    e.preventDefault();

    let response = errorValidate(forgetValidate(data));
    if(response === true)
    {
      mutate(data)
    }
    else{
      setError(response)
    }
  }

  useEffect(() => {
    if(user) 
      {
          navigate('/')
      }
  },[user]);

  return (
    <div class="account-section bg_img">
      <div class="container-fluid ml-3">
        <div class="account-title">
          <a href="/" class="logo">
            <img
              src={imageUrl("logo.png")}
              style={{ borderRadius: "25px", width: "200px" }}
              alt="logo"
            />
          </a>
        </div>
        <div class="account-wrapper">
          <div class="account-body">
            <div class="account-form" id="contactForm">
            <div class="head-section">
          <a href="/" class="logo">
            <img
              src={imageUrl("logo.png")}
              style={{ borderRadius: "25px", width: "200px" }}
              alt="logo"
            />
          </a>
        </div>
              <p>Forget Password</p>
              <div class="form-group">
                <label for="email">Email  </label>
                <input
                  type="email"
                  placeholder="Enter Your Email"
                  id="email"
                  name="email"
                  onChange={onChange}
                />
               {error?.email ? <span className="error-message">{error?.email}</span> : <></>}
                
              </div>
              <div class="form-group text-center" id="formProgress"></div>

              <div class="form-group text-center">
                <button onClick={onSubmitHandler} type="submit" id="submit2">
                  Continue
                </button>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
