import { useQuery } from "@tanstack/react-query";
import useAuth from "components/hooks/useAuth";
import useAuthenticate from "components/hooks/useAuthenticate";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import {  Route, Routes, useNavigate} from "react-router-dom";
import { logout } from "redux/slices/userSlice";
import apis from "services";
import {
  Signup,
  Signin,
  Dashboard,
  ProfileDetails,
  SelectProject,
  DesignProject,
  ForgetPassword,
  UpdatePassword,
  IssueSetting,
  CreateProject,
  DesignProjectNew
  } from "./screens";

function App() {
  const navigate  = useNavigate()
const dispatch = useDispatch();
  useAuthenticate();
  const user = useAuth();
  
  const {data, isLoading} = useQuery(['getUser'], () => apis.getOneUser(user?._id),{
    onError: function ({message}) {
      console.log(message);
      if(message === "Invalid token.")
      {
        dispatch(logout())
        toast.error("Your session has expired",{id : 1}) 
         navigate("/sign-in")
      }
    },
    onSuccess: (data) => {
      console.log(data.status)
    }
  })


  return (
    <div className="App">
        <Routes>
           <Route path="/" element={<Dashboard/>} />
        
           <Route path="/profile-details" element={<ProfileDetails/>} />
          
           <Route path="/project-name" element={<CreateProject/>} />
          
           <Route path="/project-select" element={<SelectProject/>} />
          
           <Route path="/forget" element={<ForgetPassword/>} />
          
           <Route path="/update-password" element={<UpdatePassword/>} />
          
           <Route path="/project-design" element={<DesignProject/>} />
          
           <Route path="/project-design/:id" element={<DesignProject/>} />
          
           <Route path="/new" element={<DesignProjectNew/>} />
          

           <Route path="/issue-setting" element={<IssueSetting/>} />
          
           <Route path="/dashboard" element={<Dashboard/>} />
          
           <Route path="/sign-up" element={<Signup/>} />
           
           <Route path="/sign-in" element={<Signin/>} />
       </Routes>
    </div>
  );
}
export default App;
